<template>
  <el-dialog class="practices-view-dialog"
             title="教学实践详情"
             :visible.sync="visible"
             :practicesViewDialogVisible="practicesViewDialogVisible"
             :append-to-body="true"
             :destroy-on-close="true"
             @before-close="handleClose"
             @close="handleClose"
             width="50%">
    <el-form ref="practicesViewForm">
      <el-row>
        <el-col :span="24">
          <div class="view-title"
               v-show="targetCode">{{ targetCode }}：{{ targetName }}</div>
        </el-col>
        <el-col :span="24">
          <el-form-item v-if="practicesList.length > 0">
            <el-tabs v-model="activeTab"
                     @tab-click="handeTabClick"
                     class="tab-info">
              <el-tab-pane :label="item.tagName"
                           :name="item.id+''"
                           v-for="item in practicesList"
                           :key="item.id">
                <div class="panel-data"
                     style="width: auto;min-height: 0;">
                  <div class="second-panel"
                       style="width: auto"
                       v-for="info in item.practices"
                       :key="info.id">
                    <div class="panel-header">
                      <div class="title">{{ info.practiceTitle }}</div>
                      <div class="button"></div>
                    </div>
                    <div class="panel-content">
                      <div class="content-info"
                           v-if="info.practiceContent">
                        <!-- <div class="ql-container ql-snow">
                          <div class="ql-editor" v-html="info.practiceContent"></div>
                        </div> -->
                        <div class="w-e-text-container"
                             @click="handleImgClick">
                          <div class="w-e-text"
                               v-html="info.practiceContent"></div>
                        </div>
                      </div>
                      <div class="file-info"
                           v-if="info.files">
                        <div class="file-item"
                             v-for="fileInfo in info.files"
                             :key="fileInfo.id">
                          <div class="content">
                            <img class="icon"
                                 :src="fileIconPDF"
                                 alt=""
                                 v-if="fileInfo.iconType == 'pdf'">
                            <img class="icon"
                                 :src="fileIconWord"
                                 alt=""
                                 v-if="fileInfo.iconType == 'doc'">
                            <img class="icon"
                                 :src="fileIconExcel"
                                 alt=""
                                 v-if="fileInfo.iconType == 'xls'">
                            <img class="icon"
                                 :src="fileIconPPT"
                                 alt=""
                                 v-if="fileInfo.iconType == 'ppt'" />
                            <div class="message">
                              <div class="title">{{ fileInfo.originalName }}</div>
                              <el-tooltip class="item"
                                          effect="dark"
                                          :content="fileInfo.remark"
                                          placement="top-start">
                                <div class="ltitle">{{ fileInfo.remark }}</div>
                              </el-tooltip>
                            </div>
                          </div>
                          <div class="bottom">
                            <el-tooltip class="item"
                                        effect="light"
                                        popper-class="customPopper"
                                        content="附件下载"
                                        placement="bottom"
                                        v-if="fileInfo.isDownload == '1'">
                              <img :src="fileDownloadIcon"
                                   alt=""
                                   @click="fileView(fileInfo,'download')"
                                   style="margin-right: 0.41667vw"
                                   v-if="fileInfo.isDownload == '1'" />
                            </el-tooltip>
                            <el-divider direction="vertical"
                                        v-if="fileInfo.isDownload == '1'"></el-divider>
                            <el-tooltip class="item"
                                        effect="light"
                                        popper-class="customPopper"
                                        content="附件预览"
                                        placement="bottom">
                              <img :src="fileViewIcon"
                                   alt=""
                                   @click="fileView(fileInfo,'view')"
                                   style="margin: 0 0.26042vw 0 0.41667vw" />
                            </el-tooltip>
                            <!-- <el-divider direction="vertical"></el-divider>
                            <img :src="delIcon" alt="" @click="delFile(fileInfo)" style="margin: 0 15%"/> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="empty-content-panel"
                       v-if="item.practices.length == 0">
                    <img class="empty-img"
                         :src="emptyImg"
                         alt="" />
                    <div class="empty-title">暂无内容</div>
                    <!-- <div class="empty-ltitle">需要先进行内容设置后才能进行内容操作</div> -->
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <el-image style="width: 0; height: 0;"
                      ref="viewImg"
                      :src="viewUrl"
                      :preview-src-list="viewUrlList"></el-image>
          </el-form-item>
          <div class="empty-content-panel"
               v-if="practicesList.length == 0">
            <img class="empty-img"
                 :src="emptyImg"
                 alt="" />
            <div class="empty-title">暂无教学实践内容</div>
            <!-- <div class="empty-ltitle">需要先进行内容设置后才能进行内容操作</div> -->
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import emptyImg from "@/assets/img/empty-img.png";
import fileViewIcon from "@/assets/img/file-view-icon.png";
import fileDownloadIcon from "@/assets/img/file-download-icon.png";
import delIcon from "@/assets/img/del-icon.png";
import fileIconPDF from "@/assets/img/file-icon-PDF.png";
import fileIconWord from "@/assets/img/file-icon-word.png";
import fileIconExcel from "@/assets/img/file-icon-excel.png";
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
import { getFileViewUrl, exportFile } from "@/utils/fileUtils.js";
export default {
  name: "SuggestsViewDialog",
  props: {
    practicesViewDialogVisible: {
      type: Boolean,
      default: false,
    },
    courseContentCatalogTargetId: {
      type: Number,
      default: null,
    },
    courseContentCatalogTargetInfoId: {
      type: Number,
      default: null,
    },
    courseContentCatalogTargetFlag: {
      type: String,
      default: "",
    },
    courseContentCatalogTargetTypeValue: {
      type: String,
      default: "",
    },
  },
  watch: {
    practicesViewDialogVisible () {
      this.visible = this.practicesViewDialogVisible;
    },
    courseContentCatalogTargetId (val) {
      if (val && this.practicesViewDialogVisible) {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getTargetInfo()
        this.getTargetPracticesList();
      }
    },
  },
  data () {
    return {
      visible: false, //弹框显隐
      activeTab: "", //标签绑定值
      practicesList: [], //实践列表
      targetCode: "", //目标编号
      targetName: "", //目标名称

      emptyImg, //空数据图片
      fileViewIcon, //文件预览icon
      fileDownloadIcon, //文件下载icon
      delIcon, //删除icon
      fileIconPDF, //pdfIcon
      fileIconWord, //wordIcon
      fileIconExcel, //excelIcon
      fileIconPPT,//PPTIcon

      viewUrl: '', //附件图片预览
      viewUrlList: [],//附件图片预览list
      fileTypeList: ["png", "jpg", "jpeg"], //文件类型list
      pageLoading: null, //页面loading
    };
  },
  methods: {
    getFileViewUrl,
    exportFile,
    //获取目标信息
    getTargetInfo () {
      this.$api.getTargetInfoDetailByCatalogueId({ id: this.courseContentCatalogTargetId, }).then((res) => {
        this.pageLoading.close()
        if (res.data.code === 0) {
          this.targetCode = res.data.data.info.infoCode;
          this.targetName = res.data.data.info.infoName;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    //获取目标实践list
    getTargetPracticesList () {
      this.$api.getTargetInfoByDict({ id: this.courseContentCatalogTargetId, flag: this.courseContentCatalogTargetFlag, typeValue: this.courseContentCatalogTargetTypeValue }).then((res) => {
        if (res.data.code === 0) {
          res.data.data.tags.map((item, i) => {
            if (i == 0) {
              this.activeTab = item.id + ""
              // item.practices = JSON.parse(JSON.stringify(res.data.data.practices))
            } else {
              item.practices = []
            }
          });
          this.practicesList = res.data.data.tags;
          this.$nextTick(() => {
            if (this.practicesList.length > 0) {
              this.getTargetPractices(0)
            }
          })
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //预览富文本图片
    handleImgClick (e) {
      console.log(e)
      if (e.target.nodeName == 'IMG') {
        this.viewUrl = e.target.currentSrc
        this.viewUrlList = [e.target.currentSrc]
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true
        })
      }
    },
    //预览文件
    fileView (fileInfo, type) {
      if (type == 'view') {
        let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf(".") + 1, fileInfo.fileUrl.length)
        if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
          this.viewUrl = fileInfo.fileUrl
          this.viewUrlList = [fileInfo.fileUrl]
          this.$nextTick(() => {
            this.$refs.viewImg.showViewer = true
          })
        } else {
          const { originalName, fileVersionId } = fileInfo
          this.getFileViewUrl('', { fileVersionId, name: originalName }, (data) => {
            if (data) {
              sessionStorage.setItem("viewUrl", data)
              let routeUrl = this.$router.resolve({ path: "/fileView.html" });
              window.open(routeUrl.href, '_blank');
            }
          })
        }
      } else {
        if (fileInfo.isDownload === '1') {
          let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf(".") + 1, fileInfo.fileUrl.length)
          if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
            this.$message.warning("该附件为图片，只可预览查看")
          } else {
            // window.location.href = fileInfo.fileUrl
            this.exportFile(fileInfo.fileUrl, {}, fileInfo.originalName)
          }
        } else {
          this.$message.warning("该附件不支持下载！")
        }
      }
    },
    //切换标签
    handeTabClick (val) {
      let index = this.practicesList.findIndex((item) => { return item.id == this.activeTab })
      this.$nextTick(() => {
        this.getTargetPractices(index)
      })
    },
    //获取标签下的实践详情
    getTargetPractices (index) {
      this.$api.getTargetInfoByPracticeTag({ id: this.courseContentCatalogTargetInfoId, flag: this.courseContentCatalogTargetFlag, typeValue: this.courseContentCatalogTargetTypeValue, tagId: this.activeTab }).then((res) => {
        if (res.data.code === 0) {
          let info = this.practicesList[index]
          res.data.data.map((item) => {
            if (item.files && item.files.length > 0) {
              item.files.map((info) => {
                let type = info.fileUrl.substr(info.fileUrl.lastIndexOf("."), info.fileUrl.length)
                if (type.toLowerCase().indexOf("pdf") != -1) {
                  info.iconType = 'pdf'
                }
                if (type.toLowerCase().indexOf("doc") != -1) {
                  info.iconType = 'doc'
                }
                if (type.toLowerCase().indexOf("xls") != -1) {
                  info.iconType = 'xls'
                }
                if (type.toLowerCase().indexOf("ppt") != -1) {
                  info.iconType = "ppt";
                }
              })
            }
          })
          info.practices = JSON.parse(JSON.stringify(res.data.data))
          this.$set(this.practicesList, info, index)
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //目标建议弹窗关闭
    handleClose () {
      this.targetCode = "";
      this.targetName = "";
      this.practicesList = [];
      this.activeTab = "";
      this.$emit("closeDialog", false);
    },
  },
};
</script>