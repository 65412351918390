<template>
  <el-dialog class="suggests-view-dialog" title="教学建议详情" :visible.sync="visible" :suggestsViewDialogVisible="suggestsViewDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
    <el-form ref="suggestsViewForm">
      <el-row>
        <el-col :span="24">
          <div class="view-title" v-show="targetCode">{{ targetCode }}：{{ targetName }}</div>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-table class="tableDialog" :data="tableData" style="width: 100%; max-height: 20.83333vw; margin-top: 1.04167vw;" ref="dialog-table" row-key="id" border default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren', }">
              <el-table-column type="index" label="序号" width="109" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.content === "" ? "" : scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="content" label="一级" width="270">
                <template slot-scope="scope">
                  <span>{{ scope.row.parentSuggestId !== null && scope.row.parentSuggestId !== undefined && scope.row.parentSuggestId !== "" ? "-" : scope.row.content ? scope.row.content : "" }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="content" label="二级" width="270">
                <template slot-scope="scope">
                  <span>{{ scope.row.parentSuggestId !== null && scope.row.parentSuggestId !== undefined && scope.row.parentSuggestId !== "" ? ((scope.row.level == 3 &&  scope.row.children === null) ? "-" : scope.row.content) : scope.row.parentSuggestId === null ? "/" : "" }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="content" label="三级" width="270">
                <template slot-scope="scope">
                  <span>{{ scope.row.parentSuggestId !== null && scope.row.parentSuggestId !== undefined && scope.row.parentSuggestId !== "" ? ((scope.row.level == 3 &&  scope.row.children === null) ? scope.row.content : "/") : scope.row.parentSuggestId === null ? "/" : "" }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "SuggestsViewDialog",
  props: {
    suggestsViewDialogVisible: {
      type: Boolean,
      default: false,
    },
    courseContentCatalogTargetId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    suggestsViewDialogVisible() {
      this.visible = this.suggestsViewDialogVisible;
    },
    courseContentCatalogTargetId(val) {
      if (val && this.suggestsViewDialogVisible) {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getTargetSuggestList();
      }
    },
  },
  data() {
    return {
      visible: false, //弹框显隐
      tableLoading: false, //表格loading
      tableData: [], //建议列表
      targetCode: "", //目标编号
      targetName: "", //目标名称
      pageLoading: null, //页面loading
    };
  },
  methods: {
    //获取目标建议list
    getTargetSuggestList() {
      this.$api.getTargetInfoDetailByCatalogueId({ id: this.courseContentCatalogTargetId, }).then((res) => {
        this.pageLoading.close()
        if (res.data.code === 0) {
          this.targetCode = res.data.data.info.infoCode;
          this.targetName = res.data.data.info.infoName;
          res.data.data.suggests.map((item) => {
            item.level = 1
            if(item.children){
              item.children.map((info) => {
                info.level = 2
                if(info.children){
                  info.children.map((result) => {
                    result.level = 3
                  })
                }
              })
            }
          })
          this.tableData = res.data.data.suggests;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    //目标建议弹窗关闭
    handleClose() {
      this.targetCode = "";
      this.targetName = "";
      this.tableData = [];
      this.$emit("closeDialog", false);
    },
  },
};
</script>