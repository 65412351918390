<template>
  <div class="target-setting">
    <el-dialog class="target-setting-dialog" title="指标关联" :visible.sync="visible" :targetSettingDialogVisible="targetSettingDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
      <el-form ref="targetSettingForm">
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-table class="tableDialog" :data="tableData" border style="width: 48vw" height="40vh" ref="dialog-table">
                <el-table-column prop="descriptionCode" label="指标编号" width="200" align="center"></el-table-column>
                <el-table-column prop="catalogueName" label="目标名称" align="center" width="250"></el-table-column>
                <el-table-column prop="descriptionContent" label="指标内容" align="center" width="300"></el-table-column>
                <el-table-column fixed="right" label="操作" width="200">
                  <template slot-scope="scope">
                    <span v-if="scope.row.id !== null" class="table-edit" @click="handleItem('edit', scope.row)">编辑</span>
                    <span v-if="scope.row.id === null && scope.row.targetCatalogueId === null" class="table-edit" @click="handleItem('add', scope.row)">添加</span>
                    <span v-if="scope.row.id !== null" class="table-del" @click="handleDel(scope.row)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="targetButtonloading" @click="handleClose">取 消</el-button>
        <el-button :loading="targetButtonloading" type="primary" v-if="targetSettingDialogHandleType == 'add'" @click="handleSubmit">完 成</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="targetItemTitle" :visible.sync="targetItemDialogVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleTargetItemDialogClose" @close="handleTargetItemDialogClose" width="40%">
      <el-form :model="targetItemform" ref="targetItemform" label-width="6.25vw" :rules="contentCatalogTargetId === '' ? targetItemRules1 : targetItemRules2">
        <el-form-item label="指标库：" class="form-article-item" prop="targetName">
          <el-select class="form-input" v-model="targetItemform.targetName" clearable placeholder="请选择" @change="handleTargetChange">
            <el-option v-for="item in targetOptions" :key="item.id" :label="item.guideName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标：" class="form-article-item" prop="targetItem">
          <el-select class="form-input" v-model="targetItemform.targetItem" placeholder="请选择" @change="handleTargetItemChange">
            <el-option v-for="item in targetItemOptions" :key="item.id" :label="item.catalogueName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指标维度：" class="form-article-item" prop="targetType" v-if="showFlag">
          <el-select class="form-input" v-model="targetItemform.targetType" placeholder="请选择" @change="handleTargetTypeChange">
            <el-option v-for="item in targetTypeOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指标内容：" class="form-article-item" prop="targetList" v-if="contentCatalogTargetId === ''">
          <el-select class="form-input" v-model="targetItemform.targetList" multiple placeholder="请选择">
            <el-option v-for="item in targetContentOptions" :key="item.id" :label="item.descriptionContent" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指标内容：" class="form-article-item" prop="targetList" v-if="contentCatalogTargetId !== ''">
          <el-select class="form-input" v-model="targetItemform.targetList" placeholder="请选择">
            <el-option v-for="item in targetContentOptions" :key="item.id" :label="item.descriptionContent" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleTargetItemDialogClose">取 消</el-button>
        <el-button :loading="buttonloading" type="primary" @click="handleTargetItemDialogSubmit">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "targetSetting",
  props: {
    targetSettingDialogVisible: {
      type: Boolean,
      default: false,
    },
    targetSettingDialogHandleType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: {},
    },
    courseContentCatalogId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    targetSettingDialogVisible() {
      this.visible = this.targetSettingDialogVisible;
      if (this.targetSettingDialogVisible) {
      }
    },
    targetSettingDialogHandleType(val) {
      if (val) {
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getTargetList();
        this.getTargetArmOption();
      }
    },
  },
  computed: {
    ...mapState({
      stepOptions: (state) => state.common.ageScopeList,
      levelOptions: (state) => state.common.courseLevelList,
    }),
  },
  data() {
    return {
      visible: false, //弹框显隐
      buttonloading: false, //添加目标项的弹框按钮loading
      targetButtonloading: false, // 目标列表弹框按钮loading
      tableLoading: false, //表格loading
      pageLoading: null, //页面loading
      tableData: [], //目标表格数据

      targetItemDialogVisible: false, //目标项弹窗显隐标识
      targetItemTitle: "", //指标操作弹窗标题
      targetOptions: [], //指标库下拉
      targetItemOptions: [], //目标下拉
      targetTypeOptions: [], //指标维度下拉
      targetContentOptions: [], //指标内容下拉
      targetItemform: {
        targetName: "",
        targetItem: "",
        targetType: "",
        targetList: [],
      }, //目标项form
      targetItemRules1: {
        targetName: [
          { required: true, message: "请选择指标库", trigger: "change" },
        ],
        targetItem: [
          { required: true, message: "请选择目标", trigger: "change" },
        ],
        targetType: [
          { required: true, message: "请选择指标维度", trigger: "change" },
        ],
        targetList: [
          { type: "array", required: true, message: "请选择指标内容", trigger: "change", },
        ],
      },
      targetItemRules2: {
        targetName: [
          { required: true, message: "请选择指标库", trigger: "change" },
        ],
        targetItem: [
          { required: true, message: "请选择目标", trigger: "change" },
        ],
        targetType: [
          { required: true, message: "请选择指标维度", trigger: "change" },
        ],
        targetList: [
          { required: true, message: "请选择指标内容", trigger: "change" },
        ],
      },
      addCol: {
        targetDescriptionId: null,
        targetGuideId: null,
        targetCatalogueId: null,
        id: null,
        guideName: "",
        typeValue: "",
        catalogueName: "",
        descriptionCode: "",
        descriptionContent: "",
      },
      contentCatalogTargetId: "", //指标列表id
      targetItemInfo: {},
      showFlag: true, //维度显隐标识
    };
  },
  methods: {
    //获取目标设置列表
    getTargetList() {
      this.$api.contentTargetHandle("/api/educoursethemecataloguetargetdescription/list", { courseThemeCatalogueId: this.courseContentCatalogId }, "list").then((res) => {
        this.pageLoading.close()
        if (res.data.code === 0) {
          // this.tableLoading = false;
          this.tableData = res.data.data;
          let addCol = { ...this.addCol };
          this.tableData.push(addCol);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    //获取指标库下拉
    getTargetArmOption() {
      this.$api.getTargetArmOption().then((res) => {
        if (res.data.code === 0) {
          this.targetOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //目标设置弹框关闭
    handleClose() {
      this.targetButtonloading = false;
      this.$emit("refreshPage", false);
      // this.$emit("update:targetSettingDialogVisible", false);
    },
    //目标设置弹框提交
    handleSubmit() {
      this.targetButtonloading = true;
      this.$emit("refreshPage", true);
    },
    //表格操作
    handleItem(type, row) {
      switch (type) {
        case "add":
          this.targetItemDialogVisible = true;
          this.$nextTick(() => {
            this.targetItemTitle = "添加";
          });
          break;
        case "edit":
          this.contentCatalogTargetId = row.id;
          this.targetItemform = {
            targetName: "",
            targetItem: "",
            targetType: "",
            targetList: "",
          };
          this.targetItemDialogVisible = true;
          this.$nextTick(() => {
            this.$refs.targetItemform.clearValidate()
            this.targetItemTitle = "编辑";
            this.handleTargetChange(row.targetGuideId, () => {
              this.handleTargetItemChange(row.targetCatalogueId, () => {
                if (this.targetItemInfo.flag !== 2) {
                  this.handleTargetTypeChange(row.typeValue, () => {
                    this.targetItemform.targetList = row.targetDescriptionId;
                  });
                } else {
                  this.targetItemform.targetList = row.targetDescriptionId;
                }
              });
            });
          });
          break;
      }
    },
    //删除操作
    handleDel(row) {//"确定删除该指标, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h('p', null, [
            h('span', { style: 'color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;' }, '确定要删除该指标？'),
            h('div', { style: 'color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px' }, '是否确定删除该指标，删除后，将无法恢复。')
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage"
      }).then(() => {
        this.$api.contentTargetHandle("/api/educoursethemecataloguetargetdescription/", row.id, "del").then((res) => {
          if (res.data.code === 0) {
            this.$message({ type: "success", message: "删除成功!", duration: 1500, onClose: () => {
              this.pageLoading = this.$loading({
                lock: true,
                fullscreen: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              this.getTargetList()
            }, });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => {});
    },
    //指标库变化事件
    handleTargetChange(val, callback) {
      this.targetItemform.targetName = val;
      this.targetItemform.targetItem = "";
      this.targetItemOptions = [];
      this.targetItemInfo = {};
      this.targetTypeOptions = [];
      this.targetItemform.targetType = "";
      this.targetContentOptions = [];
      if(this.contentCatalogTargetId !== ''){
        this.targetItemform.targetList = "";
      } else {
        this.targetItemform.targetList = [];
      }
      this.getTargetItemOption(callback);
    },
    //获取目标下拉
    getTargetItemOption(callback) {
      this.$api.getTargetOption(this.targetItemform.targetName).then((res) => {
        if (res.data.code === 0) {
          this.targetItemOptions = res.data.data;
          callback && callback();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //目标变化事件
    handleTargetItemChange(val, callback) {
      this.targetItemform.targetItem = val;
      let itemInfo = this.targetItemOptions.find((item) => {
        return item.id == val;
      });
      this.targetItemInfo = { ...itemInfo };
      this.targetTypeOptions = [];
      this.targetItemform.targetType = "";
      this.targetContentOptions = [];
      if(this.contentCatalogTargetId !== ''){
        this.targetItemform.targetList = "";
      } else {
        this.targetItemform.targetList = [];
      }
      if (this.targetItemInfo.flag === 2) {
        this.showFlag = false;
        this.getTargetListOption(callback);
      } else if (this.targetItemInfo.flag === "0") {
        this.showFlag = true;
        this.targetTypeOptions = JSON.parse(JSON.stringify(this.stepOptions));
        callback && callback();
      } else if (this.targetItemInfo.flag === "1") {
        this.showFlag = true;
        this.targetTypeOptions = JSON.parse(JSON.stringify(this.levelOptions));
        callback && callback();
      }
    },
    //指标维度变化事件
    handleTargetTypeChange(val, callback) {
      this.targetItemform.targetType = val;
      this.targetContentOptions = [];
      if(this.contentCatalogTargetId !== ''){
        this.targetItemform.targetList = "";
      } else {
        this.targetItemform.targetList = [];
      }
      this.getTargetListOption(callback);
    },
    //获取指标内容下拉
    getTargetListOption(callback) {
      this.$api.getTargetContentOption({ id: this.targetItemInfo.infoId, flag: this.targetItemInfo.flag, value: this.targetItemform.targetType, }).then((res) => {
        if (res.data.code === 0) {
          this.targetContentOptions = res.data.data;
          callback && callback();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //目标项弹框关闭
    handleTargetItemDialogClose() {
      this.targetItemDialogVisible = false;
      this.$nextTick(() => {
        this.targetItemform = {
          targetName: "",
          targetItem: "",
          targetType: "",
          targetList: [],
        };
        this.targetItemOptions = [];
        this.targetItemInfo = {};
        this.targetTypeOptions = [];
        this.targetContentOptions = [];
        this.targetItemTitle = "";
        this.contentCatalogTargetId = "";
        this.buttonloading = false;
        this.$refs.targetItemform.clearValidate()
      });
    },
    //目标项弹框保存
    handleTargetItemDialogSubmit() {
      this.$refs.targetItemform.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            courseThemeCatalogueId: this.courseContentCatalogId,
          };
          let url = "";
          if (this.contentCatalogTargetId != "") {
            url = "/api/educoursethemecataloguetargetdescription";
            params.id = this.contentCatalogTargetId;
            params.targetDescriptionId = this.targetItemform.targetList
          } else {
            url = "/api/educoursethemecataloguetargetdescription";
            params.targetDescriptionIds = this.targetItemform.targetList.join(",")
          }
          this.$api.contentTargetHandle(url,params,this.contentCatalogTargetId != "" ? "edit" : "add").then((res) => {
            if (res.data.code === 0) {
              this.$message({ message: this.contentCatalogTargetId != "" ? "修改成功" : "添加成功", type: "success", duration: 2000, onClose: () => {
                this.buttonloading = false;
                this.handleTargetItemDialogClose();
                this.pageLoading = this.$loading({
                  lock: true,
                  fullscreen: true,
                  text: '加载中',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                });
                this.getTargetList();
              }, });
            } else {
              this.buttonloading = false;
              this.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>